import React from "react";

function NavigationBar() {
    return (
        <div class="navigationBar">
          <a href="https://tobiasthorsen.no">Home</a>          
        </div>
    );
}

export default NavigationBar;