import React from "react";

function AboutMe() {
    return (
        <div className="aboutme-container">
            <div className="aboutme-content">
                <h3>Hi! I am </h3>
                <h1 className="aboutme-header">
                   <span className="name">Tobias With Thorsen</span> 
                </h1>
                <h3>Information Security student at University of Oslo</h3>
                <div className="aboutme-textbox">
                    <p className="aboutme-description">
                        I am 24 years old, from Bergen, but currently in Oslo pursuing my masters. 
                        <br></br>
                        My main interest during my studies are ethical hacking, and I do some web development for my line association.
                        In my free time I aslo participate in some CTFs.
                    </p>
                </div>  
            </div>
            <div className="aboutme-picture">
                <img className="profilepicture" src={require('pictures/portrettbilde.jpeg')} alt="portrett"></img>
            </div>
        </div>
    );
}

export default AboutMe;